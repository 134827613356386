import { UntypedFormGroup, FormControl, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import { MatrixValues } from 'src/app/shared/models/views-models/matrixValues.model';
import { FuzzySet } from 'src/app/shared/models/views-models/fuzzySet.model';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { Matrix } from 'src/app/shared/models/views-models/matrix.model';

@Component({
    selector: 'app-matrix',
    templateUrl: './matrix.component.html',
    styleUrls: ['./matrix.component.scss'],
})
export class MatrixComponent implements OnInit, OnChanges {
    @Input() matrixForm: UntypedFormGroup;
    @Input() matrix = new Matrix();
    @Input() fuzzySet: FuzzySet[] = [];
    @Input() antecedents: string[] = [];

    matrixHeader: string[] = [];
    outFuzzyValues: any[] = [];

    antecedent1: string;
    antecedent2: string;
    fuzzyOutSet: number;

    constructor(private formBuilder: UntypedFormBuilder) {}

    ngOnInit() {
        this.fuzzyOutSet = this.matrixForm.get('outFuzzySet').value;
        this.antecedent1 = this.antecedents[0];
        this.antecedent2 = this.antecedents[1];
        this.getMatrixHeader(this.fuzzySet);
        this.initOutFuzzy(this.fuzzyOutSet);
        this.matrixForm.controls.outFuzzySet.valueChanges.subscribe((value) => {
            this.updateMatrix(this.matrixForm.getRawValue());
            this.initOutFuzzy(value);
            this.addItems(this.matrix);
            this.fuzzyOutSet = value;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        const matrixChanges = changes.matrix;
        if (matrixChanges && matrixChanges.currentValue) {
            this.addItems(this.matrix);
        }

        if (changes.antecedents) {
            const antecedents = ([] = changes.antecedents.currentValue);
            let antecedentsLength = antecedents.length;
            let ant1Index = antecedentsLength - 2;
            let ant2Index = antecedentsLength - 1;
            this.antecedent1 = antecedents[ant1Index];
            this.antecedent2 = antecedents[ant2Index];
        }
    }

    updateMatrix(newMatrix) {
        this.matrix.fallingDown = newMatrix.fallingDown;
        this.matrix.goingUp = newMatrix.goingUp;
        this.matrix.high = newMatrix.high;
        this.matrix.low = newMatrix.low;
        this.matrix.normal = newMatrix.normal;
        this.matrix.stable = newMatrix.stable;
        this.matrix.veryHigh = newMatrix.veryHigh;
        this.matrix.veryLow = newMatrix.veryLow;
    }

    get veryHigh() {
        return this.matrixForm.get('veryHigh') as UntypedFormArray;
    }

    get high() {
        return this.matrixForm.get('high') as UntypedFormArray;
    }

    get normal() {
        return this.matrixForm.get('normal') as UntypedFormArray;
    }

    get low() {
        return this.matrixForm.get('low') as UntypedFormArray;
    }

    get veryLow() {
        return this.matrixForm.get('veryLow') as UntypedFormArray;
    }

    get goingUp() {
        return this.matrixForm.get('goingUp') as UntypedFormArray;
    }

    get stable() {
        return this.matrixForm.get('stable') as UntypedFormArray;
    }

    get fallingDown() {
        return this.matrixForm.get('fallingDown') as UntypedFormArray;
    }

    addItems(matrix: Matrix) {

        // Very high array modification
        if (matrix.veryHigh && matrix.veryHigh.length > 0) {
            const veryHigh = this.sortItems( matrix.veryHigh);
            const veryHighItems = veryHigh.map((item) => this.structureItems(item));
            const veryHighFromArray = this.formBuilder.array(veryHighItems);
            this.matrixForm.setControl('veryHigh', veryHighFromArray);
        } else this.matrixForm.setControl('veryHigh', this.formBuilder.array([]));

        // High array modification
        if (matrix.high && matrix.high.length > 0) {
            const high = this.sortItems( matrix.high);
            const highItems = high.map((item) => this.structureItems(item));
            const highFromArray = this.formBuilder.array(highItems);
            this.matrixForm.setControl('high', highFromArray);
        } else this.matrixForm.setControl('high', this.formBuilder.array([]));

        // Normal array modification
        if (matrix.normal && matrix.normal.length > 0) {
            const normal = this.sortItems( matrix.normal);
            const normalItems = normal.map((item) => this.structureItems(item));

            const normalFromArray = this.formBuilder.array(normalItems);
            this.matrixForm.setControl('normal', normalFromArray);
        } else this.matrixForm.setControl('normal', this.formBuilder.array([]));

        // Low array modification
        if (matrix.low && matrix.low.length > 0) {
            const low = this.sortItems( matrix.low);
            const lowItems = low.map((item) => this.structureItems(item));
            const lowFromArray = this.formBuilder.array(lowItems);
            this.matrixForm.setControl('low', lowFromArray);
        } else this.matrixForm.setControl('low', this.formBuilder.array([]));

        // Very low array modification
        if (matrix.veryLow && matrix.veryLow.length > 0) {
            const veryLow = this.sortItems( matrix.veryLow);
            const veryLowItems = veryLow.map((item) => this.structureItems(item));
            const veryLowFromArray = this.formBuilder.array(veryLowItems);
            this.matrixForm.setControl('veryLow', veryLowFromArray);
        } else this.matrixForm.setControl('veryLow', this.formBuilder.array([]));

        // Going Up array modification
        if (matrix.goingUp && matrix.goingUp.length > 0) {
            const goingUp = this.sortItems( matrix.goingUp);
            const goingUpItems = goingUp.map((item) => this.structureItems(item));
            const goingUpFromArray = this.formBuilder.array(goingUpItems);
            this.matrixForm.setControl('goingUp', goingUpFromArray);
        } else this.matrixForm.setControl('goingUp', this.formBuilder.array([]));

        // Stable array modification
        if (matrix.stable && matrix.stable.length > 0) {
            const stable = this.sortItems( matrix.stable);
            const stableItems = stable.map((item) => this.structureItems(item));
            const stableFromArray = this.formBuilder.array(stableItems);
            this.matrixForm.setControl('stable', stableFromArray);
        } else this.matrixForm.setControl('stable', this.formBuilder.array([]));

        // Falling Down array modification
        if (matrix.fallingDown && matrix.fallingDown.length > 0) {
            const fallingDown = this.sortItems( matrix.fallingDown);
            const fallingDownItems = fallingDown.map((item) => this.structureItems(item));
            const fallingDownFromArray = this.formBuilder.array(fallingDownItems);
            this.matrixForm.setControl('fallingDown', fallingDownFromArray);
        } else this.matrixForm.setControl('fallingDown', this.formBuilder.array([]));
    }

    private sortItems(matrixItems: Array<MatrixValues>): Array<MatrixValues>{
        const sortedTags = ['Muito Alto', 'Alto', 'Normal', 'Baixo', 'Muito Baixo', "Aumentando", "Estavel","Diminuindo"]

        let sortedItems = []

        for (const tag of sortedTags) {
            const item = matrixItems.find(t=> t.tagAntecedent2 == tag)
            if(item){
                sortedItems.push(item)
            }
        }
        return sortedItems;

    }

    structureItems(matrix: MatrixValues) {
        let value = matrix.value;
        if (value && !this.outFuzzyValues.find((option) => option == value)) {
            if (value.includes('+')) {
                matrix.value = this.outFuzzyValues[0];
            } else {
                let negativeValues = this.outFuzzyValues.filter((option) => option.includes('-'));
                let lastNegative = this.outFuzzyValues.indexOf(negativeValues[negativeValues.length - 1]);
                matrix.value = this.outFuzzyValues[lastNegative];
            }
        }
        return this.formBuilder.group({
            tagAntecedent1: [matrix.tagAntecedent1 || null],
            tagAntecedent2: [matrix.tagAntecedent2 || null],
            value: [matrix.value || '0'],
        });
    }

    initOutFuzzy(fuzzySet: any) {
        // tslint:disable-next-line: radix
        switch (parseInt(fuzzySet)) {
            case 3: {
                this.outFuzzyValues = ['+', '0', '-', 'n/a'];
                break;
            }
            case 5: {
                this.outFuzzyValues = ['++', '+', '0', '-', '--', 'n/a'];
                break;
            }
            case 7: {
                this.outFuzzyValues = ['+++', '++', '+', '0', '-', '--', '---', 'n/a'];
                break;
            }
            case 9: {
                this.outFuzzyValues = ['++++', '+++', '++', '+', '0', '-', '--', '---', '----', 'n/a'];
                break;
            }
            default: {
                this.outFuzzyValues = ['n/a'];
                break;
            }
        }
    }

    getMatrixHeader(fuzzySet: FuzzySet[]) {
        const antecedent1 =
            fuzzySet[0] !== undefined && fuzzySet[0].description ? fuzzySet[0].description : '5 Funções de Pertinência';
        const antecedent2 =
            fuzzySet[1] !== undefined && fuzzySet[1].description ? fuzzySet[1].description : '5 Funções de Pertinência';
        const ant1 = this.antecedent1;
        const ant2 = this.antecedent2;

        const fiveValorHeader = ['Muito Alto', 'Alto', 'Normal', 'Baixo', 'Muito Baixo'];
        const threeValorHeader = ['Alto', 'Normal', 'Baixo'];
        const threeTendenceHeader = ['Aumentando', 'Estável', 'Diminuindo'];

        const fiveValorForm = ['veryHigh', 'high', 'normal', 'low', 'veryLow'];
        const threeValorForm = ['high', 'normal', 'low'];
        const threeTendenceForm = ['goingUp', 'stable', 'fallingDown'];

        if (antecedent2 && antecedent2 === '5 Funções de Pertinência') {
            this.matrixHeader = fiveValorHeader;
        } else if (antecedent2 && antecedent2 === '3 Funções de Pertinência') {
            if (ant2.startsWith(VariableTypeEnum.GRD)) {
                this.matrixHeader = threeTendenceHeader;
            } else {
                this.matrixHeader = threeValorHeader;
            }
        }

        if (antecedent1 && antecedent1 === '5 Funções de Pertinência') {
            this.disableMatrixFormArray(threeValorForm);
            this.disableMatrixFormArray(threeTendenceForm);
            this.enableMatrixFormArray(fiveValorForm);
        } else if (antecedent1 && antecedent1 === '3 Funções de Pertinência') {
            this.disableMatrixFormArray(fiveValorForm);
            if (ant1.startsWith(VariableTypeEnum.GRD)) {
                this.enableMatrixFormArray(threeTendenceForm);
                this.disableMatrixFormArray(threeValorForm);
            } else {
                this.enableMatrixFormArray(threeValorForm);
                this.disableMatrixFormArray(threeTendenceForm);
            }
        }
    }

    // Enable by string array
    enableMatrixFormArray(controls: string[]) {
        controls.forEach((control) => {
            this.matrixForm.controls[control].enable();
            this.matrixForm.controls[control].setValidators([Validators.required]);
        });
    }

    // Disable by string array
    disableMatrixFormArray(controls: string[]) {
        controls.forEach((control) => {
            this.matrixForm.controls[control].disable();
            this.matrixForm.controls[control].clearValidators();
        });
    }
}
