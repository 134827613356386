import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatetimeInterval } from 'src/app/shared/models/views-models/dateInterval.model';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';

@Component({
    selector: 'app-date-navigator',
    templateUrl: './date-navigator.component.html',
    styleUrls: ['./date-navigator.component.scss'],
})
export class DateNavigatorComponent implements OnInit {
    @Input() navigatorPlaceholder: string;
    @Input() redirectedTag: string;
    @Input() tagNames: string[];
    @Input() selectedTag: string[];
    @Input() standardSearchTime: number;
    @Output() dateChanges = new EventEmitter();

    dateSelectionForm: UntypedFormGroup;
    dateInterval: DatetimeInterval;
    enableLeft: boolean = true;
    enableRight: boolean = false;
    retentionDays: number = 15;
    maxDate: string;
    minDate: any;
    endDate: string;
    startDate: string;
    tagName: string;
    initialInterval: string;

    constructor(private formBuilder: UntypedFormBuilder, private currentProjectService: CurrentProjectService) {}

    ngOnInit() {
        this.initForm();
        this.setDefaultValues();
        this.setLimitDates();
    }

    /***
     * Invoked when tag is changed
     */
    ngOnChanges(changes: SimpleChanges) {
        if (changes.redirectedTag && changes.redirectedTag.currentValue) {
            this.dateSelectionForm.patchValue({ tag: changes.redirectedTag.currentValue });
            this.applyDateInterval();
        }
    }

    initForm() {
        this.dateSelectionForm = this.formBuilder.group({
            tag: [this.selectedTag, [Validators.required]],
            startDate: [null, [Validators.required]],
            startTime: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
            endTime: [null, [Validators.required]],
            timeInterval: [null, [Validators.required]],
        });
        if (this.isShowForm()) {
            this.dateSelectionForm.disable();
        } else this.dateSelectionForm.controls['timeInterval'].disable();
    }

    setDefaultValues() {
        if(!this.standardSearchTime){
            this.standardSearchTime = 600;
        }

        let dateNow = new Date();
        let dateOffset = new Date(Date.now() - this.standardSearchTime * 1000);
        const monthOffset =
            dateOffset.getMonth() < 9 ? `0${dateOffset.getMonth() + 1}` : `${dateOffset.getMonth() + 1}`;
        const monthNow = dateNow.getMonth() < 9 ? `0${dateNow.getMonth() + 1}` : `${dateNow.getMonth() + 1}`;
        const endTime = new Date(Date.now());
        const startTime = new Date(Date.now() - this.standardSearchTime * 1000);
        this.getTimeIntervalString(startTime,endTime);
        this.dateSelectionForm.patchValue({
            startDate: `${dateOffset.getFullYear()}-${monthOffset}-${this.valide(dateOffset.getDate())}`,
            endDate: `${dateNow.getFullYear()}-${monthNow}-${this.valide(dateNow.getDate())}`,
            endTime: `${this.valide(endTime.getHours())}:${this.valide(endTime.getMinutes())}`,
            startTime: `${this.valide(startTime.getHours())}:${this.valide(startTime.getMinutes())}`,
            timeInterval: this.initialInterval,
        });
    }

    valide(number) {
        return number < 10 ? `0${number}` : number;
    }

    setLimitDates() {
        let today = new Date();
        this.maxDate = today.toISOString().substring(0, 10);
        this.minDate = new Date();
        this.minDate.setDate(today.getDate() - this.retentionDays);
        this.minDate = this.minDate.toISOString().substring(0, 10);
    }

    applyDateInterval() {
        this.dateInterval = new DatetimeInterval();
        if (this.dateSelectionForm.valid && this.validateInterval() && this.validateTagName()) {
            this.getTimeIntervalString(this.startDate, this.endDate);
            this.dateInterval.startDatetime = this.startDate;
            this.dateInterval.endDatetime = this.endDate;
            this.dateInterval.tagName = this.dateSelectionForm.get('tag').value;
            this.dateInterval.validDateInterval = true;
        } else {
            this.dateInterval.validDateInterval = false;
        }

        this.dateChanges.emit(this.dateInterval);
    }

    validateInterval() {
        let formData = this.dateSelectionForm.getRawValue();
        let validEndDate = new Date() > new Date(formData.endDate + ' ' + formData.endTime) ? true : false;
        this.startDate = new Date(formData.startDate + ' ' + formData.startTime).toISOString();

        let endDateTime = new Date(formData.endDate + ' ' + formData.endTime);
        let endTime = 59;
        endDateTime.setSeconds(endDateTime.getSeconds() + endTime);
        this.endDate = endDateTime.toISOString();

        if (this.endDate > this.startDate && validEndDate) {
            this.setDateError(false);
            return true;
        }
        this.setDateError(true);
        return false;
    }

    validateTagName() {
        let tag = this.tagNames.find((tagName) => tagName === this.dateSelectionForm.get('tag').value);
        this.tagName = tag;
        this.dateSelectionForm.controls['tag'].setErrors(null);
        if (!tag) {
            this.dateSelectionForm.controls['tag'].setErrors({ incorrect: true });
            return false;
        }
        return true;
    }

    setDateError(hasError?: boolean) {
        if (hasError) {
            this.dateSelectionForm.controls['startDate'].setErrors({ incorrect: true });
            this.dateSelectionForm.controls['startTime'].setErrors({ incorrect: true });
            this.dateSelectionForm.controls['endDate'].setErrors({ incorrect: true });
            this.dateSelectionForm.controls['endTime'].setErrors({ incorrect: true });
        } else {
            this.dateSelectionForm.controls['startDate'].setErrors(null);
            this.dateSelectionForm.controls['startTime'].setErrors(null);
            this.dateSelectionForm.controls['endDate'].setErrors(null);
            this.dateSelectionForm.controls['endTime'].setErrors(null);
        }
    }

    shiftChart(addTime: boolean) {
        let formData = this.dateSelectionForm.getRawValue();
        let startDate = new Date(formData.startDate + ' ' + formData.startTime);
        let endDate = new Date(formData.endDate + ' ' + formData.endTime);
        let newStartDate = new Date();
        let newEndDate = new Date();

        newStartDate = this.shiftDate(formData.timeInterval, startDate, addTime);
        newEndDate = this.shiftDate(formData.timeInterval, endDate, addTime);

        if (this.validateDateShift(newStartDate, newEndDate)) {
            let locale = 'pt-br';
            this.dateSelectionForm.patchValue({
                startDate: this.formatDate(new Date(newStartDate).toLocaleDateString()),
                endDate: this.formatDate(new Date(newEndDate).toLocaleDateString()),
                startTime: new Date(newStartDate).toLocaleTimeString().slice(0, 5),
                endTime: new Date(newEndDate).toLocaleTimeString().slice(0, 5),
            });
            this.applyDateInterval();
        }
    }

    /**
     * Method invoke to convert the into correpondent value
     *
     * @param interval
     * @param date
     * @param addTime
     * @returns
     */
    shiftDate(interval: String, date: Date, addTime: boolean) {
        let strSplitted = interval.split(' ', 4);
        let multiplier;

        switch (strSplitted[1]) {
            case 'min':
                multiplier = 60 * 1000;
                break;
            case 'h':
                multiplier = 60 * 60 * 1000;
                break;
            case 'd':
                multiplier = 24 * 60 * 60 * 1000;
                break;
            case 'm':
                multiplier = 30 * 24 * 60 * 60 * 1000;
                break;
        }

        let offset = parseInt(strSplitted[0]) * multiplier;
        let newTime = addTime ? date.getTime() + offset : date.getTime() - offset;
        let newDate = new Date(newTime);
        return newDate;
    }

    formatDate(date: string) {
        let splittedDate = date.split('/');
        return splittedDate.reverse().join('-');
    }

    /**
     * Validate whether the date's intervals are fitting
     * Rules :
     *  1. end-date <= Now
     *  2. start-sate >= (Now -retention days configured)
     *
     * @returns boolean
     */
    validateDateShift(startDate, endDate) {
        let dateNow = new Date();
        let dateStart = new Date().setDate(new Date().getDate() - this.retentionDays);

        if (endDate <= dateNow && startDate >= dateStart) {
            this.enableRight = true;
            this.enableLeft = true;
            return true;
        } else {
            this.enableRight = endDate > dateNow ? false : true;
            this.enableLeft = startDate < dateStart ? false : true;
            return true;
        }
    }

    setIntervalToNow() {
        let now = new Date();
        let locale = 'pt-br';
        let dateNow = now.toLocaleDateString(locale).split('/');
        let newStartDate = this.shiftDate(this.dateSelectionForm.get('timeInterval').value, now, false);
        let dateOffset = newStartDate.toLocaleDateString(locale).split('/');

        this.dateSelectionForm.patchValue({
            startDate: dateOffset[2] + '-' + dateOffset[1] + '-' + dateOffset[0],
            endDate: dateNow[2] + '-' + dateNow[1] + '-' + dateNow[0],
            endTime: now.toLocaleTimeString(locale).slice(0, 5),
            startTime: newStartDate.toLocaleTimeString(locale).slice(0, 5),
        });
        this.applyDateInterval();
    }

    /**
     * Identify interval's size and change
     *
     * @param start starttime
     * @param end endtime
     */
    getTimeIntervalString(start: any, end: any) {
        let zeroDate = new Date(0);
        let interval = new Date(new Date(end).getTime() - new Date(start).getTime());
        let formInterval;

        if (interval.getUTCFullYear() > zeroDate.getUTCFullYear()) {
            formInterval = (interval.getUTCFullYear() - zeroDate.getUTCFullYear()).toString() + ' a';
        } else if (interval.getUTCMonth() > zeroDate.getUTCMonth()) {
            formInterval = (interval.getUTCMonth() - zeroDate.getUTCMonth()).toString() + ' m';
        } else if (interval.getUTCDate() > zeroDate.getUTCDate()) {
            formInterval = (interval.getUTCDate() - zeroDate.getUTCDate()).toString() + ' d';
        } else if (interval.getUTCHours() > zeroDate.getUTCHours()) {
            formInterval = interval.getUTCHours().toString() + ' h';
        } else {
            formInterval = interval.getUTCMinutes().toString() + ' min';
        }

        this.dateSelectionForm.patchValue({
            timeInterval: formInterval,
        });

        this.initialInterval = formInterval;
    }

    resetTag() {
        this.dateSelectionForm.patchValue({ tag: '' });
    }

    checkPreviousTag() {
        if (this.tagName) {
            this.dateSelectionForm.patchValue({ tag: this.tagName });
        }
    }

    isShowForm() {
        const project = this.currentProjectService.getCurrentProject();
        return project?.versionType === 'BUILDING' && this.navigatorPlaceholder !== 'Selecione a TAG';
    }
}
