<div class="container">
    <div fxFlexFill fxLayout="column">
        <div *ngIf="confirmTitle" fxFlex class="title">
            <p>{{ confirmTitle }}</p>
        </div>
        <div fxFlex class="message" data-cy="pop-up">
            <p class="mainMessage" [innerHTML]="data.message"></p>
            <p class="secondMessage">{{ data.message_second }}</p>

            <span *ngFor=" let linkedMessage of linkedMessages">
                <p>{{ linkedMessage.message }} <a class="material-icons" (click)="openNewTab(linkedMessage.route)" style="font-size: x-large; margin-left: 5px; vertical-align: middle; line-height: 1; color:  rgb(25, 118, 210);">  {{ linkedMessage.icon }}  </a></p>
            </span>


        </div>
        <div fxFlex fxLayout="row" fxLayoutAlign="end" fxLayoutGap="15px">
            <button *ngIf="data.cancelAvailable" mat-button class="cancelDelete" type="button" (click)="cancel()">
                CANCELAR
            </button>
            <button mat-button class="blueButton" type="button" (click)="close()" data-cy="ok">
                {{ confirmButton }}
            </button>
        </div>
    </div>
</div>
