import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Response } from '../../models/response.model';
import { Fuzzy } from '../../models/views-models/fuzzy.model';
import { Tag } from '../../models/views-models/tag.model';
import { CurrentProjectService } from './current-project.service';

const API = environment.serverUrl;

@Injectable()
export class FuzzyService {
    constructor(private httpClient: HttpClient, private currentProjectService: CurrentProjectService) {}

    getAllFuzzys(): Observable<Fuzzy[]> {
        return this.httpClient.get<Response>(`${API}fuzzy/all`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const fuzzys: Fuzzy[] = response.data.sort((a, b) => a.tag.name.localeCompare(b.tag.name));
                return fuzzys;
            })
        );
    }

    getFuzzy(id: string): Observable<Fuzzy> {
        return this.httpClient.get<Response>(`${API}fuzzy/get-fuzzy/${id}`, { headers: this.getHeaders() }).pipe(
            map((response: any) => {
                const fuzzy: Fuzzy = response.data;
                return fuzzy;
            })
        );
    }

    getFuzzyDependencies(fuzzy: Fuzzy): Observable<Array<Fuzzy>> {
        const fuzzyTagsIds = [fuzzy?.antecedent1?.tag?.id, fuzzy?.antecedent2?.tag?.id, fuzzy?.tag?.id];

        const fuzzyDependenciesObservable = fuzzyTagsIds.map((tagId) =>
            this.httpClient.get<Response>(`${API}fuzzy/tag/${tagId}`, { headers: this.getHeaders() }).pipe(
                map((response: any) => {
                    const fuzzy: Array<Fuzzy> = response.data;
                    return fuzzy;
                })
            )
        );

        return combineLatest(fuzzyDependenciesObservable).pipe(
            map((fuzzyMatrix: Array<Array<Fuzzy>>) => {
                const setFuzzyList = [];

                for (const fuzzyList of fuzzyMatrix) {
                    for (const fuzzy of fuzzyList) {
                        if (!setFuzzyList.some((f) => f.id == fuzzy.id)) {
                            setFuzzyList.push(fuzzy);
                        }
                    }
                }
                return setFuzzyList;
            })
        );
    }

    deleteFuzzy(id: string): Observable<Tag[]> {
        return this.httpClient.delete<Response>(`${API}fuzzy/delete/${id}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const fuzzy: Tag[] = response.data;
                return fuzzy;
            })
        );
    }

    addFuzzy(fuzzy: Fuzzy) {
        const project = this.currentProjectService.getCurrentProject();
        fuzzy.tag.project = project;
        return this.httpClient.post<Response>(`${API}fuzzy/new`, fuzzy, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const newFuzzy: Fuzzy = response.data;
                return newFuzzy;
            })
        );
    }

    updateFuzzy(fuzzy: Fuzzy): Observable<{ fuzzy: Fuzzy; error: string }> {
        return this.httpClient.put<Response>(`${API}fuzzy/edit`, fuzzy, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const fuzzy: Fuzzy = response.data;
                const error = response.error;
                return { fuzzy, error };
            })
        );
    }

    private getHeaders(): HttpHeaders {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const token = currentUser?.token;
        const headers = new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
        });
        return headers;
    }
}
