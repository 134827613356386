import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../../models/response.model';
import { CalculationAVG } from '../../models/views-models/calculationAVG.model';
import { CalculationGRD } from '../../models/views-models/calculationGRD.model';
import { CalculationSTD } from '../../models/views-models/calculationSTD.model';
import { Fuzzy } from '../../models/views-models/fuzzy.model';

const API = environment.serverUrl;

@Injectable()
export class CalculationService {
    constructor(private httpClient: HttpClient) {}

    getAllAVGCalculations(): Observable<CalculationAVG[]> {
        return this.httpClient.get<Response>(`${API}calculation/avg/all`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const AVGCalculations: CalculationAVG[] = response.data;
                return AVGCalculations;
            })
        );
    }

    getAllGRDCalculations(): Observable<CalculationGRD[]> {
        return this.httpClient.get<Response>(`${API}calculation/grd/all`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const GRDCalculations: CalculationGRD[] = response.data;
                return GRDCalculations;
            })
        );
    }

    getAllSTDCalculations(): Observable<CalculationSTD[]> {
        return this.httpClient.get<Response>(`${API}calculation/std/all`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const STDCalculations: CalculationSTD[] = response.data;
                return STDCalculations;
            })
        );
    }

    updateAVGCalculation(avg: CalculationAVG) {
        return this.httpClient.put<Response>(`${API}calculation/avg/edit`, avg, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const AVGCalculation: CalculationAVG = response.data;
                return AVGCalculation;
            })
        );
    }

    updateGRDCalculation(grd: CalculationGRD) {
        return this.httpClient.put<Response>(`${API}calculation/grd/edit`, grd, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const GRDCalculation: CalculationGRD = response.data;
                return GRDCalculation;
            })
        );
    }

    updateSTDCalculation(std: CalculationSTD) {
        return this.httpClient.put<Response>(`${API}calculation/std/edit`, std, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const STDCalculation: CalculationSTD = response.data;
                return STDCalculation;
            })
        );
    }

    getFuzzyDependencies(tagIds: Array<string>): Observable<Array<Fuzzy>> {
        let params = new HttpParams();
        tagIds.forEach((tagId) => (params = params.append('tagId', tagId)));
        return this.httpClient
            .get<Response>(`${API}calculation/fuzzyDependencies`, { headers: this.getHeaders(), params: params })
            .pipe(map((response: Response) => response.data as Array<Fuzzy>));
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({ 'content-type': 'application/json', accept: 'application/json' });
        return headers;
    }
}
