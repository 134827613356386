import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { Antecedent } from 'src/app/shared/models/views-models/antecedent.model';
import { DatetimeInterval } from 'src/app/shared/models/views-models/dateInterval.model';
import { Fuzzy } from 'src/app/shared/models/views-models/fuzzy.model';
import { FuzzyCalculation } from 'src/app/shared/models/views-models/fuzzyCalculation.model';
import { Tag } from 'src/app/shared/models/views-models/tag.model';
import { TagValue } from 'src/app/shared/models/views-models/tagValue.model';
import { FuzzyService } from 'src/app/shared/service/views-services/fuzzy.service';
import { FuzzyCalculationService } from 'src/app/shared/service/views-services/fuzzyCalculation.service';
import { ProjectService } from 'src/app/shared/service/views-services/project.service';
import { getStandardSearchTimeInSeconds } from 'src/app/shared/utils/date.utils';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';
import { lastValueFrom } from 'rxjs';
@Component({
    templateUrl: './rules-tracking.component.html',
    styleUrls: ['./rules-tracking.component.scss'],
})
export class RulesTrackingComponent implements OnInit {
    fuzzyCalculationMerge: any;
    fuzzyCalculationOption = {
        color: ['#36A1EB', '#00CB92', '#855CF8'],
        xAxis: [
            {
                type: 'category',
                data: [],
                splitLine: {
                    show: true,
                },
                boundaryGap: false,
            },
            {
                gridIndex: 1,
                type: 'category',
                data: [],
                splitLine: {
                    show: true,
                },
                boundaryGap: false,
            },
            {
                gridIndex: 2,
                type: 'category',
                data: [],
                splitLine: {
                    show: true,
                },
                boundaryGap: false,
            },
        ],
        yAxis: [
            {
                name: 'Consequente',
                nameTextStyle: {
                    fontWeight: 'bold',
                    align: 'left',
                },
                type: 'value',
                splitLine: {
                    show: true,
                },
                max: 100,
                min: -100,
            },
            {
                name: 'Antecedente 1',
                nameTextStyle: {
                    fontWeight: 'bold',
                    align: 'left',
                },
                gridIndex: 1,
                type: 'value',
                splitLine: {
                    show: true,
                },
            },
            {
                name: 'Antecedente 2',
                nameTextStyle: {
                    fontWeight: 'bold',
                    align: 'left',
                },
                gridIndex: 2,
                type: 'value',
                splitLine: {
                    show: true,
                },
            },
        ],
        series: [
            {
                name: 'Consequente',
                data: [],
                type: 'line',
                step: 'end',
                showSymbol: false,
            },
            {
                name: 'Antecedente 1',
                data: [],
                type: 'line',
                step: 'end',
                showSymbol: false,
                xAxisIndex: 1,
                yAxisIndex: 1,
            },
            {
                name: 'Antecedente 2',
                data: [],
                type: 'line',
                step: 'end',
                showSymbol: false,
                xAxisIndex: 2,
                yAxisIndex: 2,
            },
        ],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            },

            formatter: function (params) {
                const seriesOrder = ['Consequente', 'Antecedente 1', 'Antecedente 2'];

                const orderedData = {};

                params.forEach(function (param) {
                    const seriesName = seriesOrder.find(name => param.seriesId.includes(name));
                    if (seriesName) {
                        orderedData[seriesName] = param;
                    }
                });

                const date = params[0].axisValueLabel;

                let tooltipText = `${date}<br/>`;
                seriesOrder.forEach(name => {
                    if (orderedData[name]) {
                        tooltipText += `
                            ${orderedData[name].marker} ${orderedData[name].seriesName}
                            <span style="float: right; font-weight: bold; margin-left:40px;">
                                ${orderedData[name].value}
                            </span>
                            <br/>
                        `;
                    }
                });

                return tooltipText;
        }

        },
        toolbox: {
            feature: {
                dataZoom: {
                    yAxisIndex: 'none',
                },
                saveAsImage: {
                    title: 'Salvar',
                },
            },
            right: 40,
            top: 12,
        },
        grid: [
            {
                left: 50,
                right: 50,
                height: 140,
            },
            {
                left: 50,
                right: 50,
                top: 280,
                height: 140,
            },
            {
                left: 50,
                right: 50,
                top: 500,
                height: 140,
            },
        ],
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 100,
                xAxisIndex: [0, 1, 2],
                filterMode: 'none',
            },
            {
                type: 'inside',
                start: 0,
                end: 100,
                yAxisIndex: [0, 1, 2],
                filterMode: 'none',
            },
        ],
    };

    initOpts = {
        height: 670,
    };

    dialogRefMsg: any;
    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };

    invalidDate = MessagesEnum.invalidDate;
    invalidRule = MessagesEnum.invalidRule;
    data: Fuzzy;
    samplesOffset: any;
    startDate: string;
    endDate: string;
    loadChart = false;

    consequent: Tag;
    antecedent1: Antecedent;
    antecedent2: Antecedent;
    fuzzyList: Array<Fuzzy> = [];

    lineChartLabels = [];

    antecedent1Values = [];
    antecedent2Values = [];
    consequentValues = [];
    filteredDataSet = [];
    timeLabels = [];
    fuzzyCalculationDataset: FuzzyCalculation[];

    currentFuzzy: string;
    retentionDays: number = 15;
    cycleTime: number;
    maxGapAllowed: number = 5;

    redirectedTag: any;
    startDatetime: string;
    endDatetime: string;
    fuzzyName: string;
    tagNames: any[];
    navigatorPlaceholder: string = 'Regra';
    selectedFuzzyId: string;
    standardSearchTime: number;

    constructor(
        private fuzzyService: FuzzyService,
        private fuzzyCalculationService: FuzzyCalculationService,
        private projectService: ProjectService,
        public dialog: MatDialog,
        private activeRoute: ActivatedRoute,
        private currentProjectService: CurrentProjectService
    ) {}

    async ngOnInit() {
        this.fuzzyService.getAllFuzzys().subscribe((fuzzys) => {
            this.fuzzyList = fuzzys;
            this.tagNames = this.fuzzyList.map((fuzzy) => fuzzy.tag.name);
            this.selectedFuzzyId = this.activeRoute.snapshot?.fragment;

            if (this.selectedFuzzyId) {
                this.data = fuzzys.find((fuzzy) => fuzzy.id == this.selectedFuzzyId);
            }

            if (this.data && this.data.id) {
                this.loadFuzzyInfo(this.data);
                this.reloadChart();
            }
        });
        const projectId = this.currentProjectService.getCurrentProject()?.id;
        if (!projectId) return;
        const project = await lastValueFrom(this.projectService.getProjectSettings(projectId));
        this.cycleTime = project.cycleTime;
        this.standardSearchTime = await getStandardSearchTimeInSeconds(this.cycleTime);
    }

    loadFuzzyInfo(data: any) {
        this.consequent = data.tag;
        this.antecedent1 = data.antecedent1;
        this.antecedent2 = data.antecedent2;
        this.redirectedTag = data.tag.name;
    }

    getLabel(processValues) {
        let YaxisLabel = [];
        processValues.forEach((processValue) => {
            let dataDate = new Date(processValue.timestamp.trim().replace(' ', 'T') + 'Z').toLocaleString();
            let year = '/' + new Date(processValue.timestamp).getFullYear().toString();
            YaxisLabel.push(dataDate.replace(year, ''));
            this.filteredDataSet.push(new Date(processValue.timestamp));
        });
        this.lineChartLabels = YaxisLabel.slice(this.samplesOffset);
    }

    async reloadChart() {
        this.currentFuzzy = this.fuzzyName;
        let fuzzy = this.fuzzyList.find((fuzzy) => fuzzy.tag.name == this.fuzzyName);
        if (fuzzy) {
            if (this.consequent != fuzzy.tag) {
                this.loadChart = false;
            }
            this.setChartLimits(fuzzy);
            this.consequent = fuzzy.tag;

            const waitConsData = async (): Promise<FuzzyCalculation[]> => {
                let consList = this.fuzzyCalculationService
                    .getFuzzyCalcultions(fuzzy.tag.id, this.startDatetime, this.endDatetime)
                    .toPromise();
                consList.then((fuzzyCalculations) => {
                    if (fuzzyCalculations.length > 0) {
                        this.fuzzyCalculationDataset = this.checkDatasetGaps(
                            fuzzyCalculations,
                            this.startDatetime,
                            this.endDatetime
                        );
                        this.consequentValues = this.fuzzyCalculationDataset.map((calculation) =>
                            calculation.fuzzy_value
                                ? +Number(calculation.fuzzy_value.toFixed(4))
                                : calculation.fuzzy_value
                        );
                        this.antecedent1Values = this.fuzzyCalculationDataset.map((calculation) =>
                            calculation.antecedent1_value
                                ? +Number(calculation.antecedent1_value.toFixed(4))
                                : calculation.antecedent1_value
                        );
                        this.antecedent2Values = this.fuzzyCalculationDataset.map((calculation) =>
                            calculation.antecedent2_value
                                ? +Number(calculation.antecedent2_value.toFixed(4))
                                : calculation.antecedent2_value
                        );
                        this.getLabel(this.fuzzyCalculationDataset);
                    } else {
                        this.consequentValues = [];
                    }
                });
                return consList;
            };
            await Promise.all([waitConsData()]);
            this.shareData();
            if (this.consequentValues.length > 0) {
                this.loadChart = true;
            } else {
                this.loadChart = false;
                this.defaultDialog.data.message = MessagesEnum.noDataFound;
                this.openDialog(this.defaultDialog);
            }
        }
    }

    setChartLimits(fuzzy) {
        this.antecedent1 = fuzzy.antecedent1;
        this.antecedent2 = fuzzy.antecedent2;
    }

    checkDatasetGaps(fuzzyCalculations: FuzzyCalculation[], dateStart, dateEnd) {
        let filledDataset = [];
        let startDate = new Date(dateStart).getTime();
        let firstDate = new Date(fuzzyCalculations[0].timestamp.trim().replace(' ', 'T') + 'Z').getTime();
        let endDate = new Date(dateEnd).getTime();
        let lastDate = new Date(
            fuzzyCalculations[fuzzyCalculations.length - 1].timestamp.trim().replace(' ', 'T') + 'Z'
        ).getTime();
        if ((firstDate - startDate) / 1000 > this.cycleTime * this.maxGapAllowed) {
            let fuzzyCalculation = new FuzzyCalculation();
            fuzzyCalculation.fuzzy_value = null;
            fuzzyCalculation.antecedent1_value = null;
            fuzzyCalculation.antecedent2_value = null;
            fuzzyCalculation.timestamp = dateStart.replace('T', ' ').replace('Z', '');
            filledDataset.push(fuzzyCalculation);
        }
        filledDataset = this.fillDatasetGap(startDate, firstDate, filledDataset);
        for (let i = 0; i < fuzzyCalculations.length; i++) {
            let fuzzyCalculationValue = fuzzyCalculations[i];
            let nextfuzzyCalculationValue = fuzzyCalculations[i + 1];
            filledDataset.push(fuzzyCalculationValue);
            if (i != fuzzyCalculations.length - 1) {
                let timestamp = new Date(fuzzyCalculationValue.timestamp.trim().replace(' ', 'T') + 'Z').getTime();
                let nextTimestamp = new Date(
                    nextfuzzyCalculationValue.timestamp.trim().replace(' ', 'T') + 'Z'
                ).getTime();
                filledDataset = this.fillDatasetGap(timestamp, nextTimestamp, filledDataset);
            }
        }
        filledDataset = this.fillDatasetGap(lastDate, endDate, filledDataset);
        return filledDataset;
    }

    fillDatasetGap(startDate, endDate, dataset) {
        let timeGap = (endDate - startDate) / 1000;
        if (timeGap > this.cycleTime * this.maxGapAllowed) {
            let samplesToFill = timeGap / this.cycleTime;
            for (let i = 1; i < samplesToFill; i++) {
                let sampleTimestamp = new Date(startDate + this.cycleTime * i * 1000);
                let tagValue = new TagValue();
                tagValue.value = null;
                tagValue.timestamp = sampleTimestamp.toISOString().replace('T', ' ').replace('Z', '');
                dataset.push(tagValue);
            }
        }
        return dataset;
    }

    shareData() {
        let allSeries = [
            {
                name: this.consequent.name,
                data: this.consequentValues,
            },
            {
                name: this.antecedent1.tag.name,
                data: this.antecedent1Values,
            },
            {
                name: this.antecedent2.tag.name,
                data: this.antecedent2Values,
            },
        ];

        this.fuzzyCalculationMerge = {
            xAxis: [
                {
                    data: this.lineChartLabels,
                },
                {
                    data: this.lineChartLabels,
                },
                {
                    data: this.lineChartLabels,
                },
            ],
            yAxis: [
                {
                    max: this.consequent.max,
                    min: this.consequent.min,
                },
            ],
            series: allSeries,
        };
    }

    openDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });
    }

    getChartData(dateInterval: DatetimeInterval) {
        if (dateInterval.validDateInterval) {
            this.startDatetime = dateInterval.startDatetime;
            this.endDatetime = dateInterval.endDatetime;
            this.fuzzyName = dateInterval.tagName;
            this.reloadChart();
        } else {
            this.defaultDialog.data.message = !dateInterval.validDateInterval
                ? this.invalidDate
                : MessagesEnum.invalidSP;
            this.openDialog(this.defaultDialog);
        }
    }
}
