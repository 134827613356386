export function formatDate(datestring: string) {
    let date = new Date(datestring.trim().replace(' ', 'T') + 'Z');

    let dayString = date.toLocaleDateString();
    let timeString = date.toLocaleTimeString();

    return `${dayString} ${timeString}`;
}

export function formatDateObj(date: Date) {
    let dayString = date.toLocaleDateString();
    let timeString = date.toLocaleTimeString();

    return `${dayString} ${timeString}`;
}

export function formatToServer(dateInString: string): string {
    const pad0 = (str: any, size: number) => {
        return `${str}`.padStart(size, '0');
    };
    const date = new Date(dateInString);
    const year = pad0(date.getUTCFullYear(), 4);
    const month = pad0(date.getUTCMonth() + 1, 2);
    const day = pad0(date.getUTCDate(), 2);
    const minutes = pad0(date.getUTCMinutes(), 2);
    const hours = pad0(date.getUTCHours(), 2);
    const seconds = pad0(date.getUTCSeconds(), 2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
}

export function formatFromServer(dateInString: string): string {
    const pad0 = (str: any, size: number) => {
        return `${str}`.padStart(size, '0');
    };
    const date = new Date(dateInString);
    const year = pad0(date.getFullYear(), 4);
    const month = pad0(date.getMonth() + 1, 2);
    const day = pad0(date.getDate(), 2);
    const minutes = pad0(date.getMinutes(), 2);
    const hours = pad0(date.getHours(), 2);
    const seconds = pad0(date.getSeconds(), 2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}Z`;
}

export function getStandardSearchTimeInSeconds(cycleTime: number){
    const maxSearchTime = 7200;
    const minSearchTime = 600;
    const searchMultiplier = 10;
    const calculatedTime = cycleTime * searchMultiplier;

    return Math.max(Math.min(calculatedTime, maxSearchTime), minSearchTime);
}
